<template>
  <v-container fluid>
    <v-card tile flat>
      <div class="pl-6">
        <p class="text-body-1 font-weight-regular pt-4">
          Listado de eventos para la evaluación#
          {{ numeroEvaluacion }}
        </p>
        <p class="text-body-2 text--secondary font-weight-regular mt-n3">
          <v-icon class="" color="gray">mdi-calendar-range</v-icon>
          Fecha evaluación: {{ fechaEvaluacion }}
        </p>
      </div>
    </v-card>

    <!-- tabla con información de la evaluación -->
    <div class="d-flex justify-center">
      <v-row justify="center">
        <v-col cols="10" md="9" xl="8">
          <v-data-table
            :headers="headers"
            :items="datosTabla"
            item-key="name"
            class="elevation-4"
          ></v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'listadoEventos',
  data() {
    return {
      numeroEvaluacion: 33256,
      fechaEvaluacion: '15 oct. 10:47 am', // fecha de evaluación

      //encabezados para la tabla
      headers: [
        {
          text: 'Número evaluación',
          align: 'start',
          value: 'numeroEvaluacion',
          sortable: '',
          width: 110,
        },
        {
          text: 'Usuario inicial',
          align: 'start',
          value: 'usuarioInicial',
          sortable: '',
        },
        {
          text: 'Usuario fin',
          align: 'start',
          value: 'usuarioFinal',
          sortable: '',
        },
        {
          text: 'Fecha evento',
          align: 'start',
          value: 'fechaEvento',
          sortable: '',
        },
        { text: 'Evento', align: 'start', value: 'evento', sortable: '' },
        {
          text: 'Tiempo evento',
          align: 'start',
          value: 'tiempoEvento',
          sortable: '',
        },
        {
          text: 'Estado evento',
          align: 'start',
          value: 'estado',
          sortable: '',
        },
      ],

      //datos temporales para mostrar en la tabla
      datosTabla: [
        {
          numeroEvaluacion: 33256,
          usuarioInicial: 'sector95',
          usuarioFinal: 'sector 95',
          fechaEvento: '25 oct. 10:47 am',
          evento: 'Evaluación',
          tiempoEvento: '0:00:10',
          estado: 'Completado',
        },
        {
          numeroEvaluacion: 33256,
          usuarioInicial: 'jlopez',
          usuarioFinal: 'jlopez',
          fechaEvento: '25 oct. 10:47 am',
          evento: 'Revisión contrato',
          tiempoEvento: '0:00:10',
          estado: 'Completado',
        },
        {
          numeroEvaluacion: 33256,
          usuarioInicial: 'mtomas',
          usuarioFinal: 'mtomas',
          fechaEvento: '25 oct. 10:47 am',
          evento: 'Revisión complementos',
          tiempoEvento: '0:00:10',
          estado: 'Completado',
        },
        {
          numeroEvaluacion: 33256,
          usuarioInicial: 'mtomas',
          usuarioFinal: 'mtomas',
          fechaEvento: '25 oct. 10:47 am',
          evento: 'Revisión evaluación',
          tiempoEvento: '0:00:10',
          estado: 'Completado',
        },
        {
          numeroEvaluacion: 33256,
          usuarioInicial: 'jlopez',
          usuarioFinal: 'jlopez',
          fechaEvento: '25 oct. 10:47 am',
          evento: 'Revisión referencias',
          tiempoEvento: '0:00:10',
          estado: 'Completado',
        },
        {
          numeroEvaluacion: 33256,
          usuarioInicial: 'sector95',
          usuarioFinal: 'sector 95',
          fechaEvento: '25 oct. 10:47 am',
          evento: 'Rectificación',
          tiempoEvento: '0:00:10',
          estado: 'Completado',
        },
      ],
    };
  },
  methods: {
    //funcion de ****prueba solo para agregar dos lineas mas en la tabla que muestran que se realizaron comentario
    fnVerComentario() {
      const revision = localStorage.getItem('comentario');
      if (revision) {
        console.log('se agregaron comentarios');

        //agregamos dos campos para comentarios (unicamente de PRUEBA)
        this.datosTabla.push(
          {
            numeroEvaluacion: 33256,
            usuarioInicial: 'mtomas',
            usuarioFinal: 'mtomas',
            fechaEvento: '25 oct. 10:47 am',
            evento: 'Comentario',
            tiempoEvento: '0:00:10',
            estado: 'Completado',
          },
          {
            numeroEvaluacion: 33256,
            usuarioInicial: 'mtomas',
            usuarioFinal: 'mtomas',
            fechaEvento: '25 oct. 10:47 am',
            evento: 'Comentario',
            tiempoEvento: '0:00:10',
            estado: 'Completado',
          }
        );
      } else {
        console.log('no se agregó nada');
      }

      localStorage.removeItem('comentario'); //eliminamos la info almacenada de revisión
    },
  },

  //para verificar si se tiene algun comentario extra en la lista de eventos (únicamente para pruebas)
  created() {
    this.fnVerComentario();
  },
};
</script>

<style></style>
